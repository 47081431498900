const $ = document.querySelector.bind(document);
const API_BASE = "https://howsign.herokuapp.com";

function getMinuteOfYear(now) {
  now = now || new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  const oneDay = 1000 * 60;
  return Math.floor(diff / oneDay);
}

function formatNumber(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i.toLocaleString() + "st";
  }
  if (j == 2 && k != 12) {
    return i.toLocaleString() + "nd";
  }
  if (j == 3 && k != 13) {
    return i.toLocaleString() + "rd";
  }
  return i.toLocaleString() + "th";
}

window.addEventListener("DOMContentLoaded", () => {
  const $copy = $("#copy");
  const $minute = $("#minute");
  const $year = $("#year");
  const $topic = $("#topic");

  const params = new URLSearchParams(window.location.search);
  const seed = params.get("s");
  const apiUrl = `${API_BASE}/totm`;
  fetch(seed ? `${apiUrl}?s=${seed}` : apiUrl)
    .then((resp) => resp.text())
    .then((text) => {
      $topic.textContent = text;
    });

  const now = new Date();
  const currentMinute = getMinuteOfYear(now);
  $minute.textContent = formatNumber(currentMinute);
  $minute.setAttribute("datetime", now.toISOString());
  const year = now.getFullYear().toString();
  $year.textContent = year;
  $year.setAttribute("datetime", year);

  $copy.addEventListener("click", () => {
    navigator.clipboard?.writeText($topic.innerText);
  });
});

// Keyboard shortcut to change seed
document.body.addEventListener("keyup", (event) => {
  if (!isNaN(parseInt(event.key))) {
    window.location = `/?s=${event.key}`;
  } else if (event.key === "-") {
    window.location = "/";
  }
});
